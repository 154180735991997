import React from 'react'

import { Group, DetailsCell } from '@revolut/ui-kit'

import { TableItem } from '../../../types'

type Props = TableItem

const SectionDetails = ({ items }: Props) => (
  <Group>
    {items.map(({ id, title, value }) => (
      <DetailsCell key={id}>
        <DetailsCell.Title>{title}</DetailsCell.Title>
        <DetailsCell.Content>{value}</DetailsCell.Content>
      </DetailsCell>
    ))}
  </Group>
)

export default SectionDetails
