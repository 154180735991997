import React, { FC } from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'
import {
  EntitiesSelectionItem,
  EntitiesSelectionItemValue,
  EntityType,
} from '../../../types'
import { useEntities } from './useEntities'
import { EntitiesSelectionOption } from './EntitiesSelectionOption'

type Props = EntitiesSelectionItem & {
  disabled: boolean
  changeValue(value: EntitiesSelectionItemValue): void
  openEntityDetails?(entityId: string, entityType: EntityType): void
}

const EntitiesSelectionControl: FC<Props> = ({
  value,
  changeValue: onChange,
  disabled,
  id,
  entityType,
  openEntityDetails,
}) => {
  const { enabled, loading, entities } = useEntities(id)

  const handleItemSelection = (itemId: string, selected: boolean) => {
    const selectedOptions: string[] = value?.optionIds ?? []
    if (selected) {
      onChange({ optionIds: [...selectedOptions, itemId] })
    } else {
      onChange({ optionIds: [...selectedOptions.filter(id => id !== itemId)] })
    }
  }

  const entityDetailsSupported = typeof openEntityDetails === 'function'

  const onOptionClick = (optionId: string) => {
    openEntityDetails?.(optionId, entityType)
  }

  if (!enabled) {
    return null
  }

  // TODO FRMBLDR-678 should we support error state with retry? Same for empty array
  if (loading) {
    return <ItemSkeleton />
  }

  return (
    <Group>
      {entities.map(entity => (
        <EntitiesSelectionOption
          selected={!!value && value.optionIds.some(id => entity.id === id)}
          disabled={disabled}
          key={entity.id}
          option={entity}
          onClick={entityDetailsSupported ? onOptionClick : undefined}
          onSelect={handleItemSelection}
        />
      ))}
    </Group>
  )
}

export default EntitiesSelectionControl
