import { InputGroup, StatusWidget, VStack, Skeleton } from '@revolut/ui-kit'
import React, { useEffect } from 'react'

import {
  AddressInputValue,
  AddressValidationResult,
  Country,
  CountrySelector,
} from '../../../types'
import { CountrySelectorInput } from './CountrySelectorInput'
import { useCountriesConfig } from './useCountriesConfig'
import { notReachable } from '../../helpers'
import { AddressFields } from './AddressFields'
import { SearchAddressByPostcode } from './SearchAddressByPostcode'
import { SearchAddressBySubstring } from './SearchAddressBySubstring'
import { FALLBACK_COUNTRIES } from '../common/constants/fallbackCountries'
import { AddressInputErrors, validateAddressValue } from './helpers/validateAddressValue'
import { mapErrorsToErrorMessages } from './helpers/mapErrorToErrorMessages'
import { getCountryAvailableAddressSearchMethod } from './helpers/getCountryAvailableAddressSearchMethod'
import { prepareDefaultValues } from './helpers/prepareDefaultValues'
import { ValidationResultPopup } from './ValidationResultPopup'

export type Props = {
  cityHint?: string
  countryHint?: string
  flatHint?: string
  houseNameHint?: string
  houseNumberHint?: string
  neighbourhoodHint?: string
  postcodeHint?: string
  regionHint?: string
  streetLine1Hint?: string
  streetLine2Hint?: string
  streetNameHint?: string
  optionalFieldHint?: string
  addressPickerHint?: string
  postcodePickerHint?: string
  cancelButtonHint?: string
  emptyResultsHint?: string
  noResultsHint?: string
  genericErrorSubmitHint?: string
  tryAgainHint?: string
  searchBoxHint?: string
  noSuitableAddressesHint?: string
  enterAddressManuallyHint?: string
  requiredFieldHint?: string
  streetLine1MaxLengthHint?: string
  streetLine2MaxLengthHint?: string
  streetAddressInvalidCharactersHint?: string
  cityMaxLengthHint?: string
  cityInvalidCharactersHint?: string
  invalidAddressHint?: string
  invalidPostcodeHint?: string
  orHint?: string
  value?: AddressInputValue
  validationResult?: AddressValidationResult
  countries?: Country[]
  countrySelector: CountrySelector
  isFlowSubmitted: boolean
  changeValue: (value: AddressInputValue) => void
}

const AddressInput = ({
  cityHint = 'City',
  countryHint = 'Country',
  postcodeHint = 'Postal code',
  regionHint = 'Region',
  streetLine1Hint = 'Street line 1',
  streetLine2Hint = 'Street line 2',
  optionalFieldHint = 'Optional',
  addressPickerHint = 'Search address',
  postcodePickerHint = 'Search postcode',
  cancelButtonHint = 'Cancel',
  searchBoxHint = 'Search',
  tryAgainHint = 'Try again',
  genericErrorSubmitHint = 'Something went wrong',
  enterAddressManuallyHint = 'Enter address manually',
  noResultsHint = 'No result found',
  noSuitableAddressesHint = 'My address is not here',
  emptyResultsHint = 'Search results will appear here',
  requiredFieldHint = 'Required field',
  streetLine1MaxLengthHint = 'Address line 1 cannot be longer than 50 characters',
  streetLine2MaxLengthHint = 'Address line 2 cannot be longer than 50 characters',
  streetAddressInvalidCharactersHint = 'Street address cannot contain special characters',
  cityMaxLengthHint = 'City cannot be longer than 50 characters',
  cityInvalidCharactersHint = 'City cannot contain special characters',
  orHint = 'or',
  value,
  countrySelector,
  isFlowSubmitted,
  countries = FALLBACK_COUNTRIES,
  validationResult,
  changeValue,
}: Props) => {
  const countriesConfigQuery = useCountriesConfig()

  const errors: AddressInputErrors =
    isFlowSubmitted && value ? validateAddressValue(value) : {}

  const errorMessages = mapErrorsToErrorMessages(errors, {
    requiredFieldHint,
    streetLine1MaxLengthHint,
    streetLine2MaxLengthHint,
    streetAddressInvalidCharactersHint,
    cityMaxLengthHint,
    cityInvalidCharactersHint,
  })

  useEffect(() => {
    if (!value && countrySelector.preselected) {
      changeValue(prepareDefaultValues(countrySelector.preselected))
    }
  }, [value, countrySelector, changeValue])

  switch (countriesConfigQuery.status) {
    case 'loading':
      return (
        <VStack space="s-24">
          <Skeleton height="32px" width="216px" />
          <Skeleton height="16px" width="100%" />
          <Skeleton height="16px" width="120px" />
        </VStack>
      )
    case 'success': {
      const availableCountries = countries.filter(country =>
        countriesConfigQuery.data.availableCountries.includes(country.code),
      )
      return (
        <>
          <InputGroup>
            <CountrySelectorInput
              visible={countrySelector.visible}
              editable={countrySelector.editable}
              label={countryHint}
              countrySelectorPopupTitle={countryHint}
              countrySelectorPopupNoResultHint="No result"
              countrySelectorPopupSearchPlaceholder="Search"
              value={value?.country || null}
              pending={false}
              countries={availableCountries}
              onChange={countryCode => changeValue(prepareDefaultValues(countryCode))}
            />
            {value &&
              (() => {
                const countryAvailableAddressSearchMethod = getCountryAvailableAddressSearchMethod(
                  value.country,
                  countriesConfigQuery.data,
                )

                switch (countryAvailableAddressSearchMethod) {
                  case 'searchable_by_postcode':
                    return (
                      <SearchAddressByPostcode
                        key={value.country}
                        value={value}
                        errorMessages={errorMessages}
                        label={postcodePickerHint}
                        countryCode={value.country}
                        cancelButtonLabel={cancelButtonHint}
                        searchInputPlaceholder={searchBoxHint}
                        emptySearchResultsTitle={emptyResultsHint}
                        myAddressIsNotHereLabel={noSuitableAddressesHint}
                        noResultTitle={noResultsHint}
                        errorTitle={genericErrorSubmitHint}
                        tryAgainLabel={tryAgainHint}
                        enterAddressManuallyLabel={enterAddressManuallyHint}
                        orLabel={orHint}
                        cityHint={cityHint}
                        postcodeHint={postcodeHint}
                        streetLine1Hint={streetLine1Hint}
                        streetLine2Hint={streetLine2Hint}
                        regionHint={regionHint}
                        optionalHint={optionalFieldHint}
                        onChange={changeValue}
                      />
                    )
                  case 'searchable_by_substring':
                    return (
                      <SearchAddressBySubstring
                        key={value.country}
                        value={value}
                        errorMessages={errorMessages}
                        label={addressPickerHint}
                        countryCode={value.country}
                        cancelButtonLabel={cancelButtonHint}
                        searchInputPlaceholder={searchBoxHint}
                        emptySearchResultsTitle={emptyResultsHint}
                        myAddressIsNotHereLabel={noSuitableAddressesHint}
                        noResultTitle={noResultsHint}
                        errorTitle={genericErrorSubmitHint}
                        tryAgainLabel={tryAgainHint}
                        enterAddressManuallyLabel={enterAddressManuallyHint}
                        orLabel={orHint}
                        cityHint={cityHint}
                        postcodeHint={postcodeHint}
                        streetLine1Hint={streetLine1Hint}
                        streetLine2Hint={streetLine2Hint}
                        regionHint={regionHint}
                        optionalHint={optionalFieldHint}
                        onChange={changeValue}
                      />
                    )
                  case 'manual_input':
                    return (
                      <AddressFields
                        withPostcode
                        value={value}
                        cityHint={cityHint}
                        postcodeHint={postcodeHint}
                        streetLine1Hint={streetLine1Hint}
                        streetLine2Hint={streetLine2Hint}
                        regionHint={regionHint}
                        optionalHint={optionalFieldHint}
                        errorTitle={genericErrorSubmitHint}
                        tryAgainLabel={tryAgainHint}
                        cancelButtonLabel={cancelButtonHint}
                        errorMessages={errorMessages}
                        onChange={changeValue}
                      />
                    )
                  default:
                    return notReachable(countryAvailableAddressSearchMethod)
                }
              })()}
          </InputGroup>
          {validationResult && (
            <ValidationResultPopup validationResult={validationResult} />
          )}
        </>
      )
    }
    case 'error':
      return (
        <StatusWidget>
          <StatusWidget.Title>{genericErrorSubmitHint}</StatusWidget.Title>
          <StatusWidget.Action onClick={() => countriesConfigQuery.refetch()}>
            {tryAgainHint}
          </StatusWidget.Action>
        </StatusWidget>
      )
    case 'idle':
      return null
    /* istanbul ignore next */
    default:
      return notReachable(countriesConfigQuery)
  }
}

export default AddressInput
