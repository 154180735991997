import React, { useCallback, createElement, useEffect, useMemo, useState } from 'react'
import { isPlainObject } from 'lodash'
import { VStack } from '@revolut/ui-kit'
import { Property } from 'csstype'
import { isMobile } from 'react-device-detect'

import {
  FlowViewItemType,
  fullWidthItemType,
  mapItemTypeToElement,
  staticItemTypes,
} from '../../appConstants'
import {
  Country,
  CountrySelector,
  EntityType,
  FlowViewItem,
  FlowViewItemDynamic,
  FlowViewItemValue,
} from '../../types'
import { useApi, useIsWidgetMode } from '../../providers'
import TransactionInputLegacy from '../Controls/TransactionInput/TransactionInputLegacy'

import { Props } from '.'
import {
  GroupItem,
  groupViewItems,
  mapFromCountrySelectorDtoToCountrySelector,
} from './utils'
import validateValue from './validateValue'
import { ControlItemStyled } from '../Controls/common/ControlItemStyled'

type HookProps = Pick<
  Props,
  | 'view'
  | 'changeViewItemValues'
  | 'isTransition'
  | 'openEntityDetails'
  | 'openTransactionDetails'
> & {
  hasCustomActions: boolean
  setDataFetching: (state: boolean) => void
  isSubmitted: boolean
}

type ItemsController = {
  items: React.ReactElement
  isValueValid: boolean
  isPrimaryButtonVisible: boolean
}

export default function useControlItem({
  changeViewItemValues,
  isTransition,
  view,
  hasCustomActions,
  setDataFetching,
  openEntityDetails,
  openTransactionDetails,
  isSubmitted,
}: HookProps): ItemsController {
  const isWidgetMode = useIsWidgetMode()
  const api = useApi()
  const [isPrimaryButtonVisible, setIsPrimaryButtonVisible] = useState(true)
  const { items } = view

  // Segregate SingleChoice and MultiChoice items to separate groups from rest items
  const groupedViewItems = useMemo(() => {
    return groupViewItems(items)
  }, [items])

  const singleDynamicItemOnView =
    groupedViewItems.reduce((count, groupedItem) => {
      switch (groupedItem.type) {
        case 'SINGLE':
          return !staticItemTypes.includes(groupedItem.item.type) ? count + 1 : count
        case 'GROUP':
          // Group items are always dynamic (SingleChoice, MulitChoice)
          return count + 1
        default:
          return count
      }
    }, 0) === 1

  useEffect(() => {
    const checkPrimaryButtonVisibility = () => {
      if (singleDynamicItemOnView && !hasCustomActions) {
        const groupedItem = groupedViewItems[0]
        switch (groupedItem.type) {
          case 'SINGLE': {
            switch (groupedItem.item.type) {
              case FlowViewItemType.CountryInput:
              case FlowViewItemType.CurrencyInput:
              case FlowViewItemType.YesNoInput: {
                if (groupedItem.item.required) {
                  setIsPrimaryButtonVisible(false)
                  break
                }
                break
              }
              case FlowViewItemType.SingleSelection: {
                if (groupedItem.item.style === 'CONTINUE_BUTTON') {
                  setIsPrimaryButtonVisible(false)
                  break
                }
              }
            }
            break
          }
          case 'GROUP':
            switch (groupedItem.group.itemType) {
              case FlowViewItemType.SingleChoiceLegacy: {
                const { group } = groupedItem
                if (
                  // If there is a SingleChoiceTextInput in SingleChoiceInputItem groupedItem
                  // we have to show primary button
                  group.items.some(
                    item => item.type === FlowViewItemType.SingleChoiceTextInputLegacy,
                  )
                ) {
                  setIsPrimaryButtonVisible(true)
                  return
                }
                if (group.items.some(item => item.required)) {
                  setIsPrimaryButtonVisible(false)
                }
              }
            }
        }
      }
    }

    checkPrimaryButtonVisibility()

    return () => {
      setIsPrimaryButtonVisible(true)
    }
  }, [
    groupedViewItems,
    setIsPrimaryButtonVisible,
    singleDynamicItemOnView,
    hasCustomActions,
  ])

  const isValueValid = useMemo(() => {
    return Boolean(groupedViewItems.every(validateValue))
  }, [groupedViewItems])

  // Create an item consisting of a single control
  const createFormItem = useCallback(
    (
      item: FlowViewItem,
      itemIndex: number,
      options?: {
        align: Property.TextAlign
      },
    ) => {
      if (!mapItemTypeToElement[item.type]) {
        return null
      }

      const prepareValue = (value: any) => {
        if (
          value === undefined ||
          value === '' ||
          (value.transactions && !value.transactions.length)
        ) {
          return undefined
        }
        return {
          ...(isPlainObject(value) ? value : { value }),
        }
      }

      const elementProps = {
        ...item,
        value: (item as FlowViewItemDynamic)?.value,
        disabled: isTransition,
        hasCustomActions,
        setDataFetching,
        singleDynamicItemOnView,
        openEntityDetails,
        openTransactionDetails,
        isFlowSubmitted: isSubmitted,
        countrySelector:
          item?.type === FlowViewItemType.AddressInput
            ? mapFromCountrySelectorDtoToCountrySelector(item.countrySelector)
            : undefined,
      } as {
        value: FlowViewItemValue
        disabled: boolean
        hint?: string
        underlineText?: string
        yesTitle?: string
        notTitle?: string
        buttonTitle?: string
        captureHint?: string
        takePhotoHint?: string
        confirmPhotoHint?: string
        retakePhotoHint?: string
        switchCameraHint?: string
        cityHint?: string
        countryHint?: string
        flatHint?: string
        houseNameHint?: string
        houseNumberHint?: string
        neighbourhoodHint?: string
        postcodeHint?: string
        regionHint?: string
        streetLine1Hint?: string
        streetLine2Hint?: string
        streetNameHint?: string
        optionalFieldHint?: string
        addressPickerHint?: string
        postcodePickerHint?: string
        cancelButtonHint?: string
        emptyResultsHint?: string
        noResultsHint?: string
        genericErrorSubmitHint?: string
        tryAgainHint?: string
        searchBoxHint?: string
        noSuitableAddressesHint?: string
        enterAddressManuallyHint?: string
        requiredFieldHint?: string
        streetLine1MaxLengthHint?: string
        streetLine2MaxLengthHint?: string
        streetAddressInvalidCharactersHint?: string
        cityMaxLengthHint?: string
        cityInvalidCharactersHint?: string
        orHint?: string
        hasCustomActions?: boolean
        singleDynamicItemOnView?: boolean
        countrySelector?: CountrySelector
        countries?: Country[]
        isFlowSubmitted: boolean
        changeValue?: (value: any) => void
        setDataFetching: (state: boolean) => void
        openEntityDetails?: (entityId: string, entityType: EntityType) => void
        openTransactionDetails?: (transcationId: string) => void
      }

      if (!staticItemTypes.includes(item.type)) {
        elementProps.changeValue = (value: any) =>
          changeViewItemValues({
            [(item as FlowViewItemDynamic).id]: prepareValue(value),
          })
      }

      const element = createElement(
        // Legacy, to remove after BE-driven transaction search release:
        item.type === FlowViewItemType.TransactionInput && api.getTransactionsLegacy
          ? TransactionInputLegacy
          : mapItemTypeToElement[item.type],
        elementProps,
      )

      return (
        <ControlItemStyled
          // Static items don't have ID so we use type + index as a key
          key={(item as FlowViewItemDynamic).id || `${item.type}-${itemIndex}`}
          fullWidth={fullWidthItemType.includes(item.type) || isMobile || isWidgetMode}
          style={{
            textAlign: options?.align || 'left',
          }}
        >
          {element}
        </ControlItemStyled>
      )
    },
    [
      isTransition,
      hasCustomActions,
      setDataFetching,
      singleDynamicItemOnView,
      openEntityDetails,
      openTransactionDetails,
      isSubmitted,
      api.getTransactionsLegacy,
      isWidgetMode,
      changeViewItemValues,
    ],
  )

  // Create an item consisting of multiple controls (SingleChoice, MultiChoice)
  const createGroupedFormItem = useCallback(
    (groupedItem: GroupItem) => {
      const { itemType: type, items: viewItems } = groupedItem.group

      return type ? (
        <ControlItemStyled key={`group-${viewItems[0].id}`}>
          {createElement(mapItemTypeToElement[type as FlowViewItemType], {
            items: viewItems,
            disabled: isTransition,
            hasCustomActions,
            changeViewItemValues,
            singleDynamicItemOnView,
          })}
        </ControlItemStyled>
      ) : null
    },
    [changeViewItemValues, hasCustomActions, isTransition, singleDynamicItemOnView],
  )

  return {
    isValueValid,
    isPrimaryButtonVisible,
    items: (
      <VStack space="s-16">
        {groupedViewItems.map((groupedViewItem, idx) => {
          switch (groupedViewItem.type) {
            case 'GROUP':
              return createGroupedFormItem(groupedViewItem)
            case 'SINGLE': {
              const { item } = groupedViewItem
              if (item.type === FlowViewItemType.Image) {
                return createFormItem(item, idx, { align: 'left' })
              }
              return createFormItem(item, idx)
            }
            default:
              return null
          }
        })}
      </VStack>
    ),
  }
}
