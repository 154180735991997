import {
  Cell,
  Description,
  Radio,
  RadioGroupState,
  VStack,
  HStack,
} from '@revolut/ui-kit'
import React from 'react'

import { SingleSelectionItem } from 'types'
import { HtmlStringComponent } from '../common'
import { SelectionAsset } from './SelectionAsset'
import { OptionsWrapper } from './OptionsWrapper'

interface Props
  extends Pick<
    SingleSelectionItem,
    'options' | 'style' | 'optionsSeparationStyle' | 'assetStyle'
  > {
  disabled: boolean
  getInputProps: RadioGroupState<string>['getInputProps']
  setIsMoveToTheNextViewPending: (value: boolean) => void
}

export const SingleSelectionOptions = ({
  options,
  disabled,
  style,
  optionsSeparationStyle,
  assetStyle,
  getInputProps,
  setIsMoveToTheNextViewPending,
}: Props) => (
  <OptionsWrapper areOptionsSpaced={optionsSeparationStyle === 'SPACED'}>
    {options.map(option => {
      switch (style) {
        case 'CONTINUE_BUTTON':
          return (
            <Cell
              key={option.id}
              use="button"
              variant="disclosure"
              disabled={disabled}
              onClick={() => {
                getInputProps({ value: option.id }).onChange()
                setIsMoveToTheNextViewPending(true)
              }}
            >
              <HStack align="center" space="s-16">
                {option.assetUrl && (
                  <SelectionAsset asset={option.assetUrl} style={assetStyle} />
                )}
                <VStack>
                  {option.title}
                  {option.description && (
                    <Description>
                      <HtmlStringComponent htmlString={option.description} />
                    </Description>
                  )}
                </VStack>
              </HStack>
            </Cell>
          )
        case 'RADIO_BUTTON':
          return (
            <Cell use="label" key={option.id}>
              <Radio {...getInputProps({ value: option.id })} data-testid={option.id}>
                <HStack align="center" space="s-16">
                  {option.assetUrl && (
                    <SelectionAsset asset={option.assetUrl} style={assetStyle} />
                  )}
                  <VStack>
                    <Radio.Label>{option.title}</Radio.Label>
                    {option.description && (
                      <Radio.Description>
                        <HtmlStringComponent htmlString={option.description} />
                      </Radio.Description>
                    )}
                  </VStack>
                </HStack>
              </Radio>
            </Cell>
          )
        default:
          return null
      }
    })}
  </OptionsWrapper>
)
