import { useQuery } from 'react-query'
import { useApi, useFlowId } from '../../../providers'
import { entitiesQueryKey } from './EntitiesSelection.constants'
import { EntitiesSelectionItemOption } from '../../../types'

type EntitiesHook = {
  enabled: boolean
  entities: EntitiesSelectionItemOption[]
  loading: boolean
}

export function useEntities(itemId: string): EntitiesHook {
  const { getEntities } = useApi()
  const flowId = useFlowId()

  const { data, isFetching } = useQuery(
    [entitiesQueryKey],
    () => getEntities?.(flowId, itemId),
    {
      enabled: !!getEntities,
    },
  )

  return {
    enabled: !!getEntities,
    entities: data ?? [],
    loading: isFetching,
  }
}
