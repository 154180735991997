import React, { FC } from 'react'
import { Avatar, Checkbox, Item } from '@revolut/ui-kit'
import { EntitiesSelectionItemOption } from '../../../types'

type Props = {
  option: EntitiesSelectionItemOption
  selected: boolean
  disabled?: boolean
  onClick?(optionId: string): void
  onSelect(optionId: string, selected: boolean): void
}

export const EntitiesSelectionOption: FC<Props> = ({
  option,
  disabled,
  selected,
  onClick,
  onSelect,
}) => {
  const optionTitleId = `option-${option.id}-title`
  const optionDescriptionId = option.description
    ? `option-${option.description}-description`
    : undefined

  return (
    <Item onClick={() => onClick?.(option.id)} use={onClick ? 'button' : undefined}>
      <Item.Prefix>
        <Checkbox
          onClick={e => e.stopPropagation()}
          checked={selected}
          disabled={disabled}
          onChange={event => onSelect(option.id, event.target.checked)}
          aria-labelledby={optionTitleId}
          aria-describedby={optionDescriptionId}
        />
      </Item.Prefix>
      {!!option.icon?.name && (
        <Item.Avatar>
          <Avatar useIcon={option.icon.name} />
        </Item.Avatar>
      )}
      <Item.Content>
        <Item.Title id={optionTitleId}>{option.title}</Item.Title>
        {!!option.description && (
          <Item.Description id={optionDescriptionId}>
            {option.description}
          </Item.Description>
        )}
      </Item.Content>
    </Item>
  )
}
