import React, { useState } from 'react'

import { Country } from '../../../../types'
import { Input } from './Input'
import { Popup } from './Popup'

export type Props = {
  label: string
  pending: boolean
  visible: boolean
  editable: boolean
  value: string | null
  countrySelectorPopupTitle: string
  countrySelectorPopupSearchPlaceholder: string
  countrySelectorPopupNoResultHint: string
  countries: Country[]
  onChange: (value: string) => void
}

export const CountrySelectorInput = ({
  visible,
  editable,
  label,
  value,
  pending,
  countries,
  countrySelectorPopupTitle,
  countrySelectorPopupSearchPlaceholder,
  countrySelectorPopupNoResultHint,
  onChange,
}: Props) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false)

  if (!visible) {
    return null
  }

  return (
    <>
      <Input
        label={value ? '' : label}
        countries={countries}
        countryCode={value}
        disabled={pending || !editable}
        pending={pending}
        isOpened={isPopupOpened}
        onClick={() => setIsPopupOpened(true)}
      />
      {isPopupOpened && (
        <Popup
          key={value}
          countries={countries}
          title={countrySelectorPopupTitle}
          searchPlaceholder={countrySelectorPopupSearchPlaceholder}
          noResultHint={countrySelectorPopupNoResultHint}
          countryCode={value}
          onCountrySelected={countryCode => {
            setIsPopupOpened(false)
            onChange(countryCode)
          }}
          onExit={() => setIsPopupOpened(false)}
        />
      )}
    </>
  )
}
